import DetailsDialogElement from '@github/details-dialog-element'
import {SelectedItemCountElement} from './selected-item-count'
import {SelectedItemListElement} from './selected-item-list'
import {hasDirtyFields} from '../has-interactions'
import {on} from 'delegated-events'

let locked = false

on('click', '.js-open-runner-group', function (event) {
  if (locked) return
  locked = true

  const target = event.currentTarget
  const runnerGroup = target.closest<HTMLElement>('.js-runner-group')!

  if (target.getAttribute('aria-expanded') === 'false') {
    target.setAttribute('aria-expanded', 'true')
    toggleRunners(runnerGroup, false)
    toggleRunnersChevron(runnerGroup, false)
  } else {
    target.setAttribute('aria-expanded', 'false')
    toggleRunners(runnerGroup, true)
    toggleRunnersChevron(runnerGroup, true)
  }

  locked = false
})

function toggleRunners(runnerGroup: HTMLElement, hide: boolean) {
  let nextElement = runnerGroup.nextElementSibling
  while (nextElement && isRunner(nextElement)) {
    ;(nextElement as HTMLElement).hidden = hide
    nextElement = nextElement.nextElementSibling
  }
}

function toggleRunnersChevron(runnerGroup: HTMLElement, hide: boolean) {
  runnerGroup.querySelector<HTMLElement>('.js-runner-chevron-up')!.hidden = hide
  runnerGroup.querySelector<HTMLElement>('.js-runner-chevron-down')!.hidden = !hide
}

const selected = 'SELECTED'

on(
  'details-menu-selected',
  '.js-runner-group-visibility-menu',
  function (event) {
    const target = event.detail.relatedTarget
    const runnerGroupForm = target.closest('.js-runner-group-form') as HTMLElement
    const value = target.getAttribute('value')!
    runnerGroupForm.querySelector<HTMLElement>('.js-runner-group-targets-selection')!.hidden = value !== selected
  },
  {capture: true}
)

on(
  'details-menu-selected',
  '.js-runner-group-component-visibility-menu',
  function (event) {
    const target = event.detail.relatedTarget
    const runnerGroupForm = target.closest('.js-runner-group-form') as HTMLElement
    const value = target.getAttribute('value')!
    runnerGroupForm.querySelector<HTMLElement>('.js-runner-group-targets-selection')!.hidden = value !== selected

    // hide the count of items if visibility is not set to "Selected" (i.e. Visbility is set to "All")
    runnerGroupForm.querySelector<HTMLElement>('.js-runner-group-targets-count')!.hidden = value !== selected
  },
  {capture: true}
)

document.addEventListener('details-dialog-close', function (event) {
  const selectedItems = (event.target as Element).closest<SelectedItemListElement>('selected-item-list')!
  for (const item of selectedItems.items) {
    // reset checks to last saved state
    item.checked = item.defaultChecked
  }
  selectedItems.updateCount()
})

on('click', '.js-btn-select-items', function (event) {
  const detailsDialog = (event.target as Element).closest<DetailsDialogElement>('details-dialog')!
  // save the changes to selected items
  const selectedItems = (event.target as Element).closest<SelectedItemListElement>('selected-item-list')!
  if (hasDirtyFields(detailsDialog)) {
    for (const item of selectedItems.items) {
      item.defaultChecked = item.checked
    }
  }

  // Close the details dialog
  detailsDialog.toggle(false)

  // update the selected items count
  const selectedItemCount = selectedItems.querySelector<SelectedItemCountElement>('selected-item-count')!
  selectedItemCount.updateCount(Number(selectedItems.selectedCount.textContent))
})

on('click', '.js-runner-group-checkbox', function (event) {
  const target = event.currentTarget
  if (!(target instanceof HTMLInputElement)) return

  const runnerGroup = target.closest<HTMLElement>('.js-runner-group')!
  const runnerGroupToggleButton = runnerGroup.querySelector('.js-open-runner-group')
  if (!runnerGroupToggleButton) return

  if (locked) return
  locked = true

  if (target.checked) {
    runnerGroupToggleButton.setAttribute('aria-expanded', 'true')
    toggleRunners(runnerGroup, false)
    toggleRunnersChevron(runnerGroup, false)
  }

  let nextElement = runnerGroup.nextElementSibling
  while (nextElement && isRunner(nextElement)) {
    const runnerInput = nextElement.querySelector<HTMLInputElement>('.js-bulk-actions-toggle')!
    if (isRunner(nextElement.nextElementSibling)) {
      runnerInput.checked = target.checked
    } else {
      runnerInput.checked = !target.checked
      runnerInput.click()
    }
    nextElement = nextElement.nextElementSibling
  }

  locked = false
})

function isRunner(element: Element | null): boolean {
  return element != null && element.classList.contains('js-runner')
}
